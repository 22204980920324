.dashboard-contingent {
    .modal-content { padding: 45px 30px 45px 15px; }
    .table-style {
        margin-bottom: 39px;

        h4 {
            padding-bottom: 15px;
            font-size: $font-size;
            font-weight: 600;
        }
    }
}


.bp-panel.is-form-contingent {
    margin-bottom: 45px;
    padding-bottom: 5px;

    header, .row {
        padding: 3.5px 7.5px;
        display: flex;
        & > div {
            padding: 0 3px;
            flex: none;
            text-align: center;
            &:first-child {
                flex: 1;
                text-align: left;
            }
            &:nth-child(2), &:nth-child(3) { width: 42px; }
            &:nth-child(4) { width: 30px; }
        }
    }
    .row > div:first-child {
        align-items: center;
        justify-content: space-between;
        display: flex;
    }
    input { text-align: center; }

    header {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-light;
        button, button i { color: $button-color; }
        .fa-plus-circle { font-size: 17px; }
    }
    .has-border-top {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $gray-light;
    }

    .multiselect-is-small {
        align-items: center;
        display: flex;
        .select {
            width: 50px;
            margin-right: 6px;
        }
    }
}


.bp-box.is-tickets {
    main > div { display: flex; }
    header, main > div {
        & > div:nth-child(1) { flex-grow: 1; }
        & > div:nth-child(2), & > div:nth-child(3) { width: 80px; text-align:center; }
        & > div:nth-child(2) {
            position: relative;
            left: -10px;
            justify-content: space-around;
            display: flex;
            i { margin-right: 0; }
        }
    }
    header button i { color: $button-color; }

    .input {
        width: 32px;
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
    }
    input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button { -webkit-appearance: none; }
    input[type="number"] { -moz-appearance: textfield; }

    .extra-people {
        position: relative;
        padding-top: 8px;
        &:before {
            content: ' ';
            height: 1px;
            background-color: $border-color;
            position: absolute;
            top: 3px;
            left: -12px;
            right: -12px;
        }
        & > div:first-child .input {
            width: 280px;
            text-align: left
        }
    }
}
