.add-day {
    position: relative;
    border: 1px dashed $gray-darker;
    border-radius: $border-radius;
    line-height: 24px;

    .is-edit-mode { background-color: $gray-lighter; }

    .add-days-header {
        min-height: 32px;
        background-color: $gray-lighter;
        padding-bottom: 1px;
        border-radius: $border-radius;
        display: flex;

        & > div {
            padding: 0 12px;
            align-items: center;
            display: flex;
            &:last-child { padding-right: 8px; }
            i { margin-right: 5px; }
            input[type="text"] { max-width: 300px; }
        }
    }


    .is-add-order-concept {
        input.flatpickr-input.flatpickr--range { min-width: 0; }
        .configurator {
            border-top: 1px dashed $gray-darker;
            & > .columns > .column > .level-left {
                position: absolute;
                top: 1px;
                left: 12px;
                z-index: 2;
                input { width: 300px; }
            }
        }
    }


    .select-a-concept {
        position: relative;
        border: 1px dashed $gray-darker;
        border-radius: $border-radius;
        z-index: 2;

        header {
            padding: 3px 8px 1px 12px;
            border-bottom: 1px dashed $gray-darker;
            justify-content: space-between;

            input[type="text"] {
                max-width: 350px;
                margin-right: 10px;
            }

            .multiselect {
                max-width: 240px;
            }

            .level-item {
                i {
                    margin-right: 10px;
                }
            }
        }

        .select, select { width: auto; }

        & > div:last-child { padding: 15px 8px; }
    }


    .button.is-gray i { margin-top: 2px; }
}
