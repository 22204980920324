$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;
$turquoise:    hsl(171, 100%, 41%) !default;
$border: $grey-lighter !default;

.vue-bulma-tabs {
    position: relative;
    border: 1px solid $gray-light;
    display: flex;
    flex: 1;

    &.is-layout-top { flex-direction: column; }

    .tab-content {
        background-color: $background-color;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        margin: 0;
        padding: 15px 0 0;
        flex: 1 1;
        overflow: visible;
    }

    .tab-pane {
        width: 100%;
        flex: 1 1;

        &.is-active {
            transform: translateZ(0);
            display: block;
        }

        &[class*="Out"] {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateX(0);
            transform: translateY(0);
        }
    }

    .tabs {
        margin-bottom: 0;
        font-size: 10px;
        font-weight: 700;
        line-height: 8px;
        li {
            flex-basis: 34%;
            a {
                width: 100%;
                background-color: $gray-light;
                border: 1px solid white;
                border-top: 0 none;
                border-bottom: 0 none;
            }
            &.is-active a {
                background-color: $blue-dark;
                border-color: transparent;
                span { color: white; }
            }
        }
    }
}



/*
    Used in /orders/1 - the right side part
*/
.tab-addition {
    background-color: white;
    position: relative;
    border: 1px solid $gray-light;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    &.is-dashboard {
        height: 100%;
    }

    & > .tab-addition__header header {
        display: flex;


        button {
            min-height: 35px;
            background-color: $background-color;
            position: relative;
            border-bottom: 1px solid $gray-light;
            border-right: 1px solid $gray-light;
            flex-grow: 1;
            font-size: $font-size;
            font-weight: 500;

            &:first-child { border-top-left-radius: $border-radius; }
            &:last-child {
                border-right: 0 none;
                border-top-right-radius: $border-radius;
            }

            &.is-active {
                background-color: white;
                border-bottom: 1px solid white;
                &:after {
                    content: ' ';
                    width: 100%;
                    height: 2px;
                    background-color: $accent;
                    position: absolute;
                    top: -1px;
                    left: 0;
                }
            }

            &:hover { background-color: $blue-lightest; }
        }
    }

    & > .main,
    & > main {
        padding: 45px 15px;

        &.is-gapless {
            padding: 0;
        }

        .title {
            min-height: 48px;
            margin-top: -15px;
            margin-bottom: 15px;
            align-items: center;
            justify-content: space-between;
            color: $text-color;
            font-size: $font-size;
            display: flex;
            .material-icons { vertical-align: -4px; }

            .simple-menu > button {
                line-height: 1;
                height: auto;
            }
        }
    }

    & > main .tab-content { display: none; }
}
