.trip-dashboard {
    grid-template-columns:  auto 420px;
    grid-template-rows:     auto;
    grid-column-gap:        15px;
    grid-row-gap:           40px;
    grid-template-areas:
        "main dashboard"
        "footer footer";
    display: grid;


    & > .main-panel {
        grid-area: main;
        min-width: 0;
        margin-bottom: 0;
    }
    & > .dashboard {
        grid-area: dashboard;
    }
    & > .footer    {
        grid-area: footer;
        background-color: transparent;
        padding: 0;
    }

    .days > div:nth-child(2) {
        padding-bottom: 45px;
        .day:last-child { margin-bottom: 0; }
    }
}
@media all and (max-width: 1700px) {
    .trip-dashboard { display: block; }
}


.main-panel {
    background-color: white;
    margin-bottom: 20px;
    padding: 15px 20px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;


    & > header {
        min-height: 45px;
        padding-bottom: 15px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        .title { margin-bottom: 0; }
        button.is-large {
            min-width: 150px;
            height: 30px;

            &.is-icon {
                min-width: 30px;
            }
        }
    }
}
@media all and (max-width: 1700px) {
    .main-panel { padding: 15px 5px; }
}

[page="emails"] .main-panel {
    background-color: transparent;
    padding-top: 0;
    box-shadow: none;

    .emails-page {
        grid-template-columns: 467px auto;
    }
    aside {
        background-color: white;
        box-shadow: $box-shadow;
        .emails-list_items { box-shadow: none; }
    }
    main [class^="tag-"] {
        margin-bottom: 15px;
    }

    main header {
        padding-bottom: 15px;
        [class^="tag-"] {
            margin-bottom: 0;
        }
    }
}

.add-order .field {
    margin-bottom: 2em;

    .message { margin-bottom: 0 }

    .control:not(:last-child) { margin-bottom: 15px; }

    .field {
        margin-bottom: 0;

        &:not(:last-child) { margin-bottom: 0.5em; }
    }
}


.documents-add-assignment {
    position: relative;
    margin-bottom: -15px;
    padding: 20px 0 0;

    & > div:not(.buttons) {
        margin-bottom: 10px;
        padding-bottom: 2px;
        border-bottom: 1px solid $border-color;
        &:last-child { border-bottom: 0 none; }
    }
    label { cursor: pointer; }
    input { vertical-align: -2px; }

    .buttons {
        position: absolute;
        top: -23px;
        left: 90px;
    }
    .add:hover i { color: $green; }
    .remove:hover i { color: $red; }

    .columns { margin-bottom: 7px; }
    .column {
        padding-bottom: 0;
        flex: none;
        &:nth-child(odd)  { width: 21%; }
        &:nth-child(even) { width: 29%; }
    }
}


.order-edit-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.trip-dashboard.is-voucher,
.trip-dashboard.is-client-invoice,
.is-emails-page .trip-dashboard {
    > .main-panel {
        padding: 0;
        box-shadow: 0 0 0 rgba(black, 0);
        border-radius: 0;
        background: 0;
    }
}

#email_assign.dropzone {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($gray, .5);
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;

    opacity: 0;
    visibility: hidden;
    transition: .25s opacity, .25s visibility;

    &.dz-drag-hover {
        opacity: 1;
        visibility: visible;
    }
}
