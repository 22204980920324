/*
    Used in `/clients`
*/

.hpanel {
    .hpanel-heading {
        padding: 10px 0;
        color: $text-color;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.42857143;
    }

    .hpanel-body {
        @include box-with-top-color;
        padding: 20px;

        & > .title {
            margin: -11px -20px 25px;
            padding: 0 20px 6px;
            border-bottom: 1px solid $border-color;
            font-size: $font-size;
        }

        .select, .select select { width: 100%; }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }


    .text-heading {
        padding-bottom: 30px;
        .float-right { float: right; }
        .button.is-small { height: 27px; }
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.collapse-icon {
    transition:transform 0.3s;
    cursor: pointer;

    &.is-rotated {
        transform: rotate(180deg);
    }
}

.collapse-toggle {
    background: white;
    border-radius: $border-radius;
    border: 1px solid $gray-lighter;
    padding: 2px 10px;
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    cursor: pointer;

    .collapse-icon {
        margin-left: 15px;
    }
}
.panel-content-button {
    margin-bottom: 13px;
    padding-right: 26px;
    align-self: self-end;
}
.panel {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    font-size: 11px;

    &:not(.is-borderless) {
        .panel-block:first-child,
        .panel-heading:first-child,
        .panel-tabs:first-child {
            border-top: 2px solid $accent;
        }
    }

    &-buttons {
        display: flex;
        align-items: center;

        > * {
            margin-right: 5px;
        }
    }

    &-heading {
        background: white;
        padding: 2px 15px;
        min-height: 40px;
        margin-bottom: 0;

        font-size: $font-size;
        text-transform: uppercase;
        font-weight: 700;
        color: $text-color;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .panel-label {
            text-transform: none;
            white-space: nowrap;
            flex: 1;

            .columns {
                margin-top: 0;
                margin-bottom: 0;

                .column {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &-block {
        background: white;
        padding: 12px 17px;
        transition: .4s padding;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        .panel-content {
            display: block;
            width: 100%;

            .mb {
                margin-bottom: 1.5em;
            }
        }

        &.is-spaceless {
            padding: 0;
        }
    }

    &.is-collapsed {
        .panel-heading {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        .panel-block {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
            overflow: hidden;
        }
    }

    &.is-alt {
        > .panel-heading {
            background: $background-color;

            .material-icons {
                color: $blue-dark;
            }

            .button > * {
                color: inherit;
            }
        }

        > .panel-block {
            background: $background-color;
        }
    }

    &.is-small {
        .panel-heading {
            padding: 5px 10px;
        }

        .panel-block {
            padding: 15px 10px;
        }

        &.is-collapsed {
            .panel-block {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &-footer {
        padding: 15px;
        background: $background-color;
        border-top: 1px solid $border-color;
    }
}

// CAROUSEL panel
.panel {
    .panel-carousel {
        position: relative;
    }

    .carousel-button {
        position: absolute;
        top: 50%;

        font-size: 30px;
        width: 50px;
        height: 36px;
        margin-top: -18px;
        line-height: 36px;
        text-align: center;

        &-prev {
            left: 0
        }

        &-next {
            right: 0;
        }
    }
}

// Placeholders
.placeholders {
    .placeholder {
        background-color: $background-color;

        &.is-form {
            text-transform: none;

            .field {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                font-weight: $font-weight-normal;

                .mr { margin-right: 10px; }
                .ml { margin-left: 10px; }
                .mx { margin-left: 10px; margin-right: 10px; }
            }

            .material-icons { line-height: inherit; }

            .input .is-narrow { width: 80px; }
            .input-price      { width: 80px; }
            .button { font-size: $font-size; }

            .multiselect {
                &__content { font-weight: $font-weight-normal; }
                &__option::after { display: none; }
                &.is-medium { width: 300px; }
            }
        }

        &.is-empty {
            display: table-caption;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;

            > div {
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .simple-menu {
                vertical-align: middle;
            }
        }
    }

    &.panel {
        font-size: $font-size;

        .panel-heading {
            background: $background-color;
            font-weight: 600;
            font-size: inherit;
        }

        .panel-blocks {
            display: table;
            border: 1px solid $border-color;
            border-top: 0;
            width: 100%;

            .panel-block {
                display: table-row;
                padding-top: 0;
                padding-bottom: 0;

                .column {
                    padding: 5px 10px;
                    display: table-cell;
                    vertical-align: middle;
                    border-bottom: 1px solid $border-color;

                    &.is-narrow {
                        white-space: nowrap;
                        width: 1%;
                    }

                    .buttons {
                        margin-bottom: 0;
                        flex-wrap: nowrap;
                        justify-content: flex-end;

                        .button {
                            margin-bottom: 0;
                        }
                    }
                }

                &:last-child {
                    .column {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.message {
    background: $gray-lighter;
}

.box {
    border-radius: $border-radius
}
