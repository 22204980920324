.is-faqs {
    .filters {
        padding-bottom: 20px;
        display: flex;
        input:first-child { margin-right: 5px; }
    }


    aside.main-panel { padding-bottom: 0; }


    .accordion { margin-bottom: 25px; }
    .accordion-header {
        background-color: $gray-lighter;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
    }


    .row:not(.is-head):hover { cursor: pointer; }
    .redesign-table {
        border-top: 0 none;

        .cell.is-small-cell { width: 45px; }
        .is-active { background-color: $blue-lightest; }
    }
    .btn-delete.is-loading:after { top: -6px; }


    & > .panel {
        width: 100%;
        background-color: white;
        position: relative;
        margin-bottom: 0;

        .panel-heading {
            border-left: 0 none;
            border-right: 0 none;
            border-bottom: 0 none;
            .question {
                flex-grow: 1;
                margin-right: 10px;
            }
            .category {
                width: 200px;
                text-align: right;
                margin-right: 10px;
            }

            &.is-view {
                .question { padding-right: 150px; }
                .category {
                    width: auto;
                    background-color: $accent;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 0 20px;
                    color: white;
                    font-weight: $font-weight-normal;
                    text-transform: capitalize;
                    line-height: 18px;
                    margin-right: 0;
                }
            }
        }

        .panel-content {
            padding: 15px;
        }
    }
    .ql-editor { min-height: 620px; }

    .is-close, .is-save {
        position: absolute;
        bottom: -26px;
        i { margin-right: 0; }
    }
    .is-close { right: 36px; }
    .is-save  { right: 0; }
}

aside.is-faqs {
    .faqs-overlay,
    .faqs-wrapper {
        position: absolute;
        top: 52px;
        right: 0;
        bottom: 0;
    }

    .faqs-overlay {
        z-index: 100;
        background: rgba(black, .7);
        width: 100%;
    }

    .faqs-wrapper {
        position: absolute;
        width: 420px;
        background: #fff;
        padding: 15px;
        z-index: 101;
        overflow-y: auto;
    }

    .bp-panel {
        padding-top: 15px;
        border-bottom: 1px solid $gray-light;
        border-top: 1px solid $gray-light;
        box-shadow: 0 0 0 rgba(white, 0)
    }
}
