.modal.placeholder {

    .redesign-table.is-costs {
        .row .cell{
            &:first-child { width: auto; }

            &:nth-child(4),
            &:nth-child(5) { text-align: right; }

            &:nth-child(2),
            &:nth-child(3) { text-align: center; }

            &:not(:first-child) {
                width: 120px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            .price-view {
                width: 100%;
                padding-right: 0;
            }
        }
    }

    .is-empty-cost {
        padding: 30px 0;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
}

.is-costs + .for-costs-total {
    margin: -6px 0 0;
    justify-content: flex-end;
    display: flex;

    & > div {
        min-height: 30px;
        line-height: 30px;
        background-color: $blue-dark;
        &, b { color: white; }

        &:first-child  {
            padding: 0px 31px 0 37px;
            border-radius: 0 0 0 $border-radius;
        }
        &:last-child {
            padding-right: 3px;
            border-radius: 0 0 $border-radius 0;
            text-align: right;
        }
    }

    .price-view { color: white; }
    .price-view:before {
        top: 7px;
        left: -1px;
    }
}