.orders-add {
    .column  { padding-right: 60px; }

    .is-client {
        position: relative;

        & > a {
            width: 30px;
            position: absolute;
            top: 4px;
            right: -30px;
            font-size: 16px;
            text-align: center;
            i { color: $blue-dark; }
        }
    }
}