/* Use (with default value `none`):
        @include user-select;
    or (with custom value)
        @include user-select(auto);
*/
@mixin user-select($arg: none) {
    -webkit-touch-callout: $arg;
      -webkit-user-select: $arg;
         -moz-user-select: $arg;
          -ms-user-select: $arg;
              user-select: $arg;
}


@mixin font-family-material() {
    font-family: 'Material Icons';
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga' 1;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
}

@mixin font-family-awesome() {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* White box base styling
*/
@mixin white-box() {
    background-color: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}


@mixin box-with-top-color($bg-color: white, $top-color: $accent) {
    background-color: $bg-color;
    border-top: 2px solid $top-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}


@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
