.modal.placeholder .emails-page {
    margin-bottom: 0;
    grid-row-gap: 15px;
    grid-template-areas: "aside  main"
                         "header header";

    & > header { display: none; }

    & > aside, & > main { border-top: 2px solid $accent; }
    .buttons-wrapper, & > main { background-color: $background-color; }


    main {
        header { display: block; }
        .list {
            a {
                width: auto;
                color: $blue-light;
                text-align: left;
                &:hover { text-decoration: underline; }
                span { display: none; }
            }
        }
        .email { height: 597px; }
    }

    .page-title { display: none; }
}
