/*
    Overriding Flatpickr calendar
    https://github.com/vue-bulma/datepicker
*/
$width_in_aside: 278px;

input.flatpickr-input {
    width: $width_in_aside;
}
div.flatpickr-calendar {
    &, .flatpickr-weekdays {
        width: $width_in_aside;
    }

    .dayContainer {
        max-width: $width_in_aside;
        min-width: $width_in_aside;
        width: $width_in_aside;
    }

    &--months {
        .flatpickr-innerContainer {
            display: none;
        }

        .flatpickr-month {
            height: 38px;

            .flatpickr-prev-month,
            .flatpickr-next-month{
                height: 38px;
                line-height: 22px;
            }

            .flatpickr-current-month {
                padding: 6px 0;
                height: 38px;
            }    
        }
    }
}