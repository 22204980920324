.text-small {
  font-size: 12px;
}

.is-vertical-middle {
  vertical-align: middle;
}

hr {
  margin: 1rem 0;
}

p {
  font-size: $font-size;
  color: $text-color;
}

// Headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  color: $text-color;
  margin-bottom: 1em;
}

h1, .h1 {
  font-size: $font-size;
}

h2, .h2, h3, .h3, h4, .h4 {
  font-size: $font-size;
}

h1, .h1, h2, .h2, h3, .h3 {
  text-transform: uppercase;
}

h1, .h1, h2, .h2 {
  font-weight: bold;
}

h3, .h3, h4, .h4 {
  font-weight: 600;
}

.level-item {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    margin-bottom: 0;
  }
}

// Titles
.title {
  font-size: $font-size;
  text-transform: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:not(.is-spaced) + .subtitle {
    margin-top: -.75rem;
  }
}

.subtitle {
  font-size: $font-size;
  text-transform: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.fa-angle-times::before {
  content: "\F105\F104";
  letter-spacing: -0.15em;
  font-size: 1.3em;
}

.is-text-gray {
  color: $gray;
}
