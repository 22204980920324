.invoice-page {
    .modal {
        width: 100%;
        height: 100%;
        display: block;

        .modal-overlay { display: block; }
        .modal-content {
            position: absolute;
            left: auto;
            right: 0;
            margin-top: 158px;
        }
    }
    &.no-side .invoice-list-for-form { margin-right: 0; }


    /*                      *\
    ========== Main ==========
    \*                      */
    .invoice-list-for-form {
        position: relative;
        z-index: 21;
        margin-right: 580px;
        transition: margin 0.4s ease 0s;

        .search {
            max-width: 780px;
            padding-bottom: 20px;
            align-items: center;
            display: flex;

            & > i {
                font-size: 18px;
                margin-right: 10px;
            }
            .multiselect:nth-child(3) {
                width: 250px;
                margin-left: 20px;
            }
        }


        .box {
            border-radius: $border-radius;
            .accordion {
                border-top: 0 none;
                box-shadow: none;
                &:last-child .accordion-header { border-radius: $border-radius; }
            }
            .accordion-header {
                min-height: 30px;
                background-color: $background-color;
                padding: 0;
            }

            header, .list-item {
                border-bottom: 1px solid $border-color;
                align-items: center;
                display: flex;

                & > div:nth-child(1) {
                    padding-right: 20px;
                    flex-grow: 1;
                    @include ellipsis();
                    i { margin-right: 5px; }
                }
                & > div:nth-child(2) { min-width: 120px; }
                & > div:nth-child(3) { min-width: 145px; }
                & > div:nth-child(4) { min-width:  60px; }
                & > div:nth-child(5) { min-width:  50px; }
                & > div:nth-child(6) { min-width:  70px; text-align: right; }
                & > div:nth-child(7) { min-width:  90px; text-align: right; }
                & > div:nth-child(8) { min-width:  60px; text-align: right; }
                & > div:nth-child(9) { min-width:  60px; text-align: right; }
                & > div:nth-child(10) {
                    min-width: 65px;
                    padding-right: 13px;
                    text-align: right;
                    button {
                        padding: 0 3px;
                        i { color: $button-color; }
                    }
                }
            }
            header { font-weight: 600; }
            .list-item {
                min-height: 30px;
                padding: 0 15px;
                .is-red { color: $red-light; }
            }
        }
    }
}


.trip-dashboard {
    .invoice-page .invoice-list-for-form { margin-right: 145px; }
    .invoice-page.no-side .invoice-list-for-form { margin-right: 0; }
}
