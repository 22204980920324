.emails-page {
    // grid-template-columns:  758px auto;
    // grid-template-rows:     auto;
    // grid-column-gap:        15px;
    // grid-row-gap:           40px;
    // grid-template-areas:
    //     "aside main";
    // display: grid;

    display: flex;
    flex: 1;
    align-items: stretch;

    .is-empty {
        padding: 20px;
        text-align: center;
    }

    .page-title {
        padding-bottom: 20px;
        font-weight: 700;
    }
}


.emails-page, .emails-list {
    /*
        Tags (for order, name, ...)
    */
    .tag-green, .tag-gray, .tag-white {
        margin-top: 4px;
        margin-left: 5px;
        padding: 0 10px 1px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        color: white;
        font-weight: 600;
        line-height: 16px;
        cursor: default;
        display: inline-block;
        &:first-child { margin-left: 0; }
    }
    .tag-green {
        background-color: $green;
    }
    .tag-gray {
        background-color: $gray-darker;
        margin-left: 5px;
    }
    .tag-white {
        background-color: white;
        color: $text-color;
    }
    a, button { &.tag-green, &.tag-gray, &.tag-white { cursor: pointer; }}
}


// Used in the user dashboard
.email-preview-on-hover {
    width: 60%;
    height: 500px;
    background: white;
    position: fixed;
    top: 186px;
    left: 400px;
    box-shadow: $box-shadow;
    overflow: hidden;
    z-index: 1;

    header {
        background-color: $gray-lighter;
        padding: 10px 24px 5px;
        border-bottom: 1px solid $gray;
        border-radius: 2px 2px 0 0;
        font-weight: 600;
        font-size: $font-size;
    }

    iframe {
        position: relative;
        right: -16px;
        html { padding-right: 20px; }
    }
}




@import './aside';
@import './main';

@import './new_email';
@import './list';
