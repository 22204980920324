.rating {
    text-align: center;
    font-size: $font-size;

    &__value { position: absolute; }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 5px;
        border-radius: 50%;
        background: white;

        svg { transform: rotate(-90deg); }
    }

    .circle__meter,
    .circle__value {
        fill: none;
    }

    .circle__meter { stroke: #e6e6e6; }

    .circle__value {
        stroke: $color-green;
        stroke-linecap: round;
    }
}

