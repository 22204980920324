.card {
    border-radius: $border-radius;

    &.is-loading {
        opacity: .5;
    }

    &.is-primary {
        border-top: 2px solid $accent;
    }

    &.is-voucher {
        border: 1px solid $accent;
        border-radius: $border-radius;
        font-size: $font-size;
        box-shadow: 0 0 0 rgba(white, 0);
        margin-bottom: 10px;
        padding-left: .75rem;
        padding-right: .75rem;
        background: $background-color;

        &.is-faded {
            opacity: .5;
        }

        .card-heading {
            padding-top: 1.25em;
            border-bottom: 1px solid $gray-light;
            margin-bottom: 0;

            p {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-left: 10px;
                }
            }

            .material-icons {
                margin-right: 5px;
                font-size: 16px;
                line-height: inherit;
            }
        }

        .card-block {
            padding-top: .75em;
            padding-bottom: .75em;
        }


        .mr {
            margin-right: 10px;
        }

        .button,
        .buttons {
            &.is-absolute {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 5px;
                margin-right: 15px;
            }
        }

        .label {
            font-size: $font-size;
            font-weight: $font-weight-normal;
        }

        .columns {
            &.is-separated {
                padding-top: .75rem;
                padding-bottom: .75rem;
                margin-top: 0;
                margin-bottom: 0;

                .column {
                    padding-top: 0;
                    padding-bottom: 0;

                    &:not(:first-child) {
                        border-left: 1px solid $gray-light;
                    }
                }

                .label {
                    color: $accent;
                }
            }

            .is-half {
                width: 48%;
            }
        }

        .bb {
            border-bottom: 1px solid $gray-light;
            padding-bottom: 10px;
            margin-bottom: 5px;
        }

    }

    &--dashboard {
        margin-bottom: .75rem;

        .card-content {
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        .item {
            &__heading {
                margin-bottom: .75rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .tag {
                    font-size: 10px;
                    font-weight: bold;
                    height: auto;
                    padding: 1px 25px;
                }

                .buttons {
                    margin-bottom: 0;
                }
            }

            &__title {
                display: flex;
                align-items: center;

                .title {
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: normal;

                    margin: 0;
                    margin-right: 15px;
                }

                .tag {
                    margin-right: 10px;
                }

                .stars {
                    margin-right: 10px;
                }

                .icons {
                    .material-icons {
                        margin-right: 3px;
                        font-size: 22px;
                    }
                }
            }

            &__text {
                margin-bottom: .5rem;
                font-size: 12px;
                max-width: 1000px;
            }

            &__attributes {
                list-style: none;
                padding-left: 0;
                margin-bottom: .75rem;

                display: flex;
                flex-direction: row;
                align-items: center;

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &:not(:last-child) {
                        border-right: 1px solid #ccc;
                        padding-right: 15px;
                        margin-right: 15px;
                    }

                    .material-icons,
                    .fa {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    &.property {
                        display: inline-flex;
                        align-items: center;

                        .is-text-green {
                            color: $green;
                            margin-right: 5px;
                        }

                        span {
                            margin-right: 5px;
                            margin-left: 5px;
                        }
                    }
                }
            }

            &__buttons {
                margin-bottom: .25rem;
            }
        }
    }

    &.is-scrollable {
        max-height: 150px;
        overflow-y: auto;
    }
}

.card {
    &-actions {
        position: absolute;
        top: 0;
        right: 0;
    }

    &-tag {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &.is-image {
        border: 0;
        padding: 0;
        box-shadow: 0 0 7px rgba(#000, .2);
        display: block;
        width: 100%;
        max-width: 1000px;
        background-color: $gray-lighter;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px;

        &.is-empty {
            padding-bottom: 0;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .placeholder-text {
            color: $text-color;
        }

        .card-actions {
            top: 5px;

            button {
                width: 22px;
                height: 22px;
                text-align: center;

                margin-right: 5px;
                background: white;
                border-radius: $border-radius;
                border: 1px solid #ccc;

                .material-icons {
                    line-height: 22px;
                }
            }
        }

        .card-tag {
            bottom: 5px;
            right: 5px;

            .tag {
                height: auto;
            }
        }
    }
}

@mixin panelCard {
    min-height: 85px;
    padding: 15px;
    font-size: 12px;
    background: rgb(247, 249, 250);

    &-content{
        position: relative;
        padding: 0;

        .mb {
            margin-bottom: .5rem;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 3px;
                }

                .material-icons {
                    margin-right: 7px;
                    font-size: 12px;
                }
            }
        }
    }

    &.is-image {
        padding: 0;

        img {
            max-width: 200px;
            max-height: 150px;
        }
    }
}

.panel {
    .card {
        @include panelCard;
    }
}
