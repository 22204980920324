#app {
    flex-direction: column;
    display: flex;

    &.locked {
        height: 100vh;
        overflow-y: hidden;
    }
}

#app > header {
    height: $header-height;
    background-color: white;
    border-bottom: 1px solid $border-color;
    flex: none;
    display: flex;

    .logo {
        width: $menu-width;
        height: $header-height;
        justify-content: center;
        align-items: center;
        display: flex;
        float: left;

        img {
            max-width: 150px;
            width: 47%;
        }
    }

    .header-link {
        float: left;
        padding: 15px 26px 17px 26px;
        border-left:  1px solid $border-color;
        border-right: 1px solid $border-color;
        cursor: pointer;
    }

    .page-name {
        padding: 0 0 0 15px;
        line-height: 50px;
        font-size: 14px;
        font-weight: 600;
        flex: 1;
    }

    .nav-search {
        width: 180px;
        height: 29px;
        margin: 11px 15px 10px 10px;
        padding: 0 10px;
        border: 1px solid $border-color;
    }

    // Only on orders pages on small screens
    .btn-show-right-side-on-small,
    .btn-show-left-side-on-small {
        width: 130px;
        border-left: 1px solid $border-color;
        font-weight: 600;
        display: none;
        i {
            margin-right: 5px;
            color: $gray-dark;
            font-size: 25px;
        }
        &:hover {
            background-color: $background-color;
            &, i { color: $text-color; }
        }
    }
    @media all and (max-width: 1700px) {
        .btn-show-right-side-on-small.is-visible { display: block; }
        .btn-show-left-side-on-small {
            border-right: 1px solid $border-color;
            border-left: 0;
        }
    }

    @media all and (max-width: 1919px) {
        .btn-show-right-side-on-small.is-visible,
        .btn-show-left-side-on-small.is-visible { display: block; }
    }

    .btn-help {
        padding: 14px 14px 14px 0;
        z-index: 10;

        .simple-menu-content {
            margin-top: 30px;
        }
    }
}


#app > .wrapper {
    margin: 0 15px;
    padding: 15px 0 0 0;
    transition: margin 0.4s ease 0s;
    position: relative;
    flex-grow: 1;
    & > div {
        width: 100%;
        margin-bottom: 27px;
    }
}


#app > ul.nav {
    max-height: 100%;
    width: $menu-width;
    background: $background-color;
    position: absolute;
    bottom: 0;
    left: 0;
    top: $header-height + 1;
    margin-left: -$menu-width;
    padding-right: 0;
    transition: margin 0.4s ease 0s;
    flex-direction: column;
    overflow-y: hidden;

    a, button {
        padding: 10px 15px;
        color: $gray-dark;
        text-align: left;
        font-weight: 600;
        display: block;
        font-size: 12px;
    }

    .active a, a:hover, .active button, button:hover, .is-open button {
        color: $text-color;
    }

    & > li > ul {
        height: 0;
        padding-left: 10px;
        overflow: hidden;
        transition: height 0.2s ease-out;

        button, a {
            font-size: $font-size;
            padding: 5px 15px;
        }
    }
    .is-open ul { margin-bottom: 10px; }

    button {
        width: 100%;
        position: relative;
        &:before {
            content: "\f104";
            position: absolute;
            top: 13px;
            right: 15px;
            font-family: 'FontAwesome';
        }
    }
    .is-open button:before { content: "\f107"; }
}


#app.is-menu-opened > ul.nav { margin-left: 0; }
#app.is-menu-opened > .wrapper { margin-left: $menu-width; }

#app.is-right-aside-hidden > .wrapper {
    margin-right: 0;
}


@media all and (max-width: 1700px) {
    #app > .wrapper {
        margin: 0 5px;
    }
}
