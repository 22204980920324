.loading-container {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    background: rgba(white, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.loading-data {
    width: 100%;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    display: flex;

    .spinner { margin: 20px; }

    &.is-small {
        font-size: 12px;

        .spinner {
            width: 40px;
            height: 30px;
            flex-basis: 40px;

            > div {
                width: 4px;
            }
        }
    }

    &.is-vertical { flex-direction: column; }
}

/* Inside a table */
table.table .loading-data {
    background-color: white;
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 13px;
}


.spinner {
    margin: 20px auto;
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 15px;
    flex: none;
    flex-basis: 60px;

    & > div {
        background-color: $blue-dark;
        height: 100%;
        width: 8px;
        display: inline-block;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
