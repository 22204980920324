.search-day-concept {
    padding: 0 12px 10px;
    font-size: 12px;
    display: flex;

    &.is-gapless {
        padding: 0;
        margin-right: -.75rem;
        margin-left: -.75rem;
    }

    h4 {
        padding-bottom: 10px;
        font-weight: 600;
    }

    .day-concepts > div, .placeholder-concepts > div {
        background-color: white;
        padding: 0 10px;
        border-bottom: 1px solid $border-color;
        line-height: 28px;
    }


    .day-concepts {
        width: 100%;
        font-size: $font-size;

        & > div {
            position: relative;
            cursor: pointer;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &:hover { background-color: $background-color; }
            &.is-selected { background-color: $background-color; }

            .tag {
                width: 100px;
                @include ellipsis();
                padding-left: 5px;
                padding-right: 5px;
                position: absolute;
                top: 2px;
                left: 5px;

                > span {
                    color: inherit;
                }

                &.is-placeholder {
                    height: 22px;
                    background: $background-color;
                }

                & + span,
                & + div {
                    padding-left: 105px;
                }
            }

            .index {
                min-width: 18px;
                padding-left: 5px;
                padding-right: 5px;
                height: 18px;
                border-radius: 9px;
                background: $accent;
                color: #fff;
                line-height: 18px;
                text-align: center;

                margin-right: 10px;
                flex-shrink: 0;
                flex-grow: 0;
            }

            &.is-variation {
                justify-content: space-between;

                .level-left {
                    flex: 1;
                    overflow: hidden;

                    .level-item {
                        width: 50%;
                        justify-content: flex-start;

                        &:not(:last-child) {
                            margin-right: 0;
                            padding-right: .75rem;
                        }
                    }
                }

                .icons {
                    width: 30px;
                    text-align: right;
                    flex-shrink: 0;
                    flex-grow: 0;

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    i.material-icons,
                    .fa {
                        font-size: 13px;
                    }

                    i.fa-external-link-square {
                        margin-right: 0;
                        vertical-align: 0;
                    }
                }
            }

            &.is-placeholder {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                overflow: hidden;

                .level-left {
                    flex: 1;

                    i.material-icons {
                        margin-right: 10px;
                    }

                    > span {
                        flex: 1;
                        height: 30px;
                        position: relative;

                        > .is-truncated {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                        }
                    }
                }

                &.is-optional {
                    i.material-icons, span {
                        color: $accent
                    }
                }

                .form {
                    width: 100px;
                    padding-left: 10px;
                    flex-shrink: 0;

                    .input {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .placeholder-concepts {
        .is-optional { color: $accent; }
        i { margin: 0 11px 0 5px; }
    }


    .day-concept {
        position: relative;
        padding-left: 72px;
        padding-right: 0;
        & > i {
            position: absolute;
            top: 53px;
            left: 11px;
            font-size: 40px;
        }
    }


    .has-more-info {
        display: flex;
        justify-content: space-between;
    }
}



.is-concept .placeholder-view {
    padding-left: 0;
    border-bottom: 1px solid $border-color;
    &:last-child { border-bottom: 0 none; }

    .action-buttons { right: 0; }
}



.configurator {
    .panel {
        &-heading {
            .is-light {
                font-weight: normal;
                font-style: normal;
                font-size: 11px;
                color: $gray-dark;
                text-transform: none;
            }
        }
    }
}
