/*
    Used in `/clients`
*/

.pagination-info {
    padding-right: 20px;
    font-size: 13px;
}

.pagination {
    justify-content: flex-end;
    font-size: $font-size;

    .pagination-buttons {
        @include user-select;
        span {
            cursor: default;
            display: inline-block;
        }
        button, span {
            float: left;
            padding:3px 9px;
            margin-left: -1px;
            border: 1px solid $border-color;
            color: $gray-dark;
            font-size: 13px;
            line-height: 1.42857143;
            display: inline-block;
        }
        button {
            &:hover {
                background-color: $background-color;
            }
            &.active {
                background-color: $button-color;
                border-color: $button-border-color;
                color: white;
                cursor: default;
            }
            &.disabled {
                color: $text-disabled;
                cursor: default;
                &:hover { background-color: transparent; }
            }
            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .pagination-link {
        &.is-current {
            background: $blue-dark;
            border-color: $button-border-color;
        }
    }

    .pagination-link,
    .pagination-next,
    .pagination-previous {
        background: #fff;
        color: $text-color;

        &.is-current {
            background: $blue-active;
            color: #fff;
        }
    }
}

.custom-page {
    margin-left: 15px;
    font-size: 13px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .input {
        width: 50px;
        text-align: center;
        vertical-align: 0;
        margin-left: 5px;
    }
}
