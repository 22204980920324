.tag {
    &:not(body) {
        color: white;
        background: $gray-darker;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        padding-left: 1.5em;
        padding-right: 1.5em;



        &.is-red         { background-color: $red-light;}
        &.is-blue-dark   { background-color: $blue-dark; }
        &.is-green       { background-color: $green; }
        &.is-orange      { background-color: $orange; }
        &.is-blue        { background-color: $blue; }
        &.is-yellow      { background-color: $yellow; }
        &.is-purple      { background-color: $purple; }
        &.is-brown, &.is-zero      { background-color: $brown; }

        // Category key based colors
        &.is-nature { background: $green; }
        &.is-culture { background: $yellow; }
        &.is-activity { background: $red-light; }
        &.is-recreation { background: $blue; }
        &.is-classic, &.is-low { background: $blue-dark; }

        &.is-round { background: $green; }
        &.is-active { background: $red-light; }

        // Panel tags
        &.is-billing { background: $yellow; }

        &.is-delivery,
        &.is-map, &.is-new { background: $blue; }

        &.is-primary,
        &.is-photo { background: $green; }

        &.is-high, &.is-hard { background: $red-light; }
        &.is-medium {
            background: $yellow;
            font-size: $font-size;
        }



        &.is-white {
            background: #fff;
            border: 1px solid $gray-light;
            color: $text-color;

            &.has-blue-border {
                border: 1px solid #9dd3d8;
                padding-left: .75em;
                padding-right: .75em;
            }

            i {
                color: inherit;
            }
        }
    }

    &.is-offer, &.is-calculation {
        min-height: 0;
    }

    .has-margin-right { margin-right: 5px; }
}
a.tag.is-white {
    &:hover {
        background-color: $background-color;
        text-decoration: none;
    }
}

.orderConcepts {
    .tag {
        &.is-activity { background: $blue; }
        &.is-culture { background: $blue-dark; }
    }
}

.badge {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    display: inline-block;
    font-size: 9px;
    text-align: center;
    line-height: 24px;

    &.is-new {background: $green}
    &.is-19 {background: $blue}
    &.is-20 {background: $blue-darker}
    &.is-21 {background: $lila}
    &.is-22 {background: $brown}
    &.is-23 {background: $pink-dark}
    &.is-icon {
        background: $gray-darker;
        i { color: white !important }
    }
}

.badges .badge { margin-left: 5px; }

button.badge {
    border: 1px solid $border-color;
    background: white;
    line-height: 16px;
    width: 18px;
    height: 18px;

    i {
        font-size: 16px;
        font-weight: bold;
        color: white !important;
        margin-bottom: 3px;
    }

    &.is-active {
        background: $accent;
        border-color: $accent;
        text-align: center;

    }
}

.table {
    .badges .badge {
        margin-left: 0px;
        margin-right: 5px;
    }

    .icons {
        i {
            width: 25px;
            text-align: center;
            margin-right: 5px;
        }
    }
}
