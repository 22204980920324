div.notifications {
  position: fixed;
  bottom: -17px;
  right: 0;
  z-index: 1257;
  pointer-events: none;
}

@media screen and (min-width: 769px), print {
  .notifications {
      max-width: 320px;
  }
}

.notifications .notification {
  margin: 20px;
}

.notification {
  position: relative;
  min-width: 240px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  pointer-events: all;
}

.panel.is-alt {
  .notification {
    background: $gray;
  }

  border-radius: $border-radius;
}

.notification.is-small {
  padding-top: 8px;
  padding-bottom: 8px;
  background: $gray-lighter;
}

.alert {
    padding: 15px;
    background-color: $gray-lighter;

    p {
        color: $text-color;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &.is-danger {
        background-color: $pink_light;

        strong {
            font-weight: bold;
            color: $pink_dark;
        }
    }
}
