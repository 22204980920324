.is-emails-page .trip-dashboard > .main-panel {
    display: flex;
}

.emails-page > main {
    min-height: 1125px;
    flex: 1;
    @include box-with-top-color;


    .action-buttons {
        min-height: 40px;
        padding: 6px 10px;
        border-bottom: 1px solid $gray;
        display: flex;

        > div {
            flex: 1;
            display: flex;
        }

        .button:not(.is-primary) {
            margin-right: 0;
            border-radius: 0;
            font-weight: 600;
            color: $text-color;
            flex: 1;
            &.is-success { color: white; }

            i {
                position: absolute;
                left: 12px;
                font-size: 18px;
            }
        }
        .modal-content footer .button { flex: 0; }
        & > div:first-child {
            flex: 1;
            & > button:not(.is-primary) { width: 100%; }
        }
    }


    header {
        background-color: $background-color;
        position: relative;
        padding: 20px 20px 0;
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: 1px solid $border-color;

        & > div {
            min-height: 22px;
            line-height: 22px;
        }

        b {
            min-width: 50px;
            display: inline-block;
        }

        .date {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }


    .email {
        min-height: 950px;
        background-color: white;
        padding: 0 0 0 20px;
    }


    .attachments {
        padding: 13px;

        button {
            margin-bottom: 5px;
            vertical-align: 0;
        }
    }
    .list {
        margin: 0 -12px;
        flex-wrap: wrap;
        display: flex;

        a {
            width: 260px;
            //padding: 12px 12px 5px;
            text-align: center;
            display: flex;
            &:hover { background-color: $background-color; }
        }
        span {
            width: 255px;
            height: 26px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
            background-color: white;
            margin-bottom: 5px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
            //line-height: 130px;
            display: flex;

        }
        i {
            color: $blue;
            font-size: 15px;
            padding-top: 4px;
            margin-right: 5px;

        }
        b {
            @include ellipsis();
            font-weight: $font-weight-normal;
            display: block;
        }
    }


    .empty-view {
        height: 100%;
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}




