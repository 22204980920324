$gray-lightest: #F7F9FA;
$gray-lighter:  #EAEAEA;
$gray-light:    #dddddd;
$gray:          #CCCCCC;
$gray-dark:     #9D9FA2;
$gray-darker:   #6A6C6F;    // Text colors
$gray-darkest:  #363636;
$black:         #000000;

$blue-lightest: #E8F4FF;
$blue-lighter:  #74b2db;
$blue-light:    #3C8DBC;
$blue:          #3B96D6;
$blue-dark:     #34495E;
$blue-darker:   #22303D;
$blue-active:   #3498DB;
$light_blue:    #35CDDB;
$blue-strong:   #0022ff;
$blue-marine:   #1e18cc;

$red:           #FF3860;
$red-light:     #FF6A6A;


$pink:          #F7EEED;
$pink_dark:     #E68C87;
$pink_light:    #faedea;


$yellow:        #FFB606;

$green-bright:  #79ff3b;
$green-bright2: #9bf6b6;
$green:         #62CB31;
$ocean-green:   #41b883;
$green-light:   #f4fff7;



$lila:          #663399;
$purple:        #995AB1;


$brown:         #9F4C0F;


$turqoise:      #60A4AB;
$turqoise-dark: #5FA3AA;


$orange:        #E6AB87;

$gray-zebra:   #fafafa;











