.order-list {
    h5 {
        padding-bottom: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }


    .filters {
        padding-bottom: 40px;
        display: flex;
        .material-icons {
            top: 3px;
            font-size: 22px;
            vertical-align: -7px;
        }
        .fa { font-size: 19px; }
        .select, select { height: 27px; }
    }


    .table-search {
        width: 100%;
        .fa { font-size: 12px; }
    }


    .table-dates {
        padding-left: 50px;
        line-height: 27px;
        .flatpickr-input[readonly] {
            height: 27px;
            width: 70px;
            font-size: 12px;
        }
    }


    [class*="is-fixed-width"] {
        position: relative;
        margin-left: 60px;
        i {
            position: absolute;
            top: 5px;
            left: -26px;
        }
    }
    .hpanel-body .is-fixed-width { width: 150px; }


    .material-icons { vertical-align: -4px; }
}