.fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s ease-out;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}


.slide-enter-active, .slide-leave-active {
    transition: max-height 0.3s ease-out;
}



/*
    Simple Menu transitions
*/
.scale-enter-active, .scale-leave-active {
    transform-origin: top right;
    transition: transform 0.2s ease-out;
}
.scale-enter, .scale-leave-to {
    transform: scale(0);
}



.scale-y-enter-active, .scale-y-leave-active {
    transform-origin: top;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.scale-y-enter, .scale-y-leave-to {
    transform: scaleY(0);
    opacity: 0;
}
.scale-y-enter-to, .scale-y-leave {
    transform: scaleY(1);
    opacity: 1;
}

.dropdown {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 350ms;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-image: linear-gradient(to top, white, rgba(white, 0));
  }

  &-enter,
  &-leave-to { opacity: 0 }

  &-leave,
  &-enter-to { opacity: 1 }

  &-enter-active,
  &-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity 200ms ease-in-out;
  }

  &-enter-active { transition-delay: 100ms }
}

.columns .column {
  transition: all .5s;
}

.column-leave-active {
  position: absolute;
}

.column-enter,
.column-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
