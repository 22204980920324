.trip-details {
    > .main-panel {
        background :0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(#000, 0);

        > .tab-addition {
            margin-bottom: 30px;
        }
    }

    .details,
    .menus {
        .days-switch {
            position: relative;

            &::before {
                content: "";

                position: absolute;
                top: 0;
                bottom: 0;

                width: 0;
                border-left:1px solid $border-color;
                border-right: 1px solid $border-color;
                left: (50px / 2);
                margin-left: -1px;
            }

            .day-button {
                position: relative;
                width: 100px;
                display: flex;
                flex-direction: row;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                button {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid $border-color;
                    box-shadow: 0 0 5px rgba(black, .1);
                    background: white;
                    font-size: 10px;
                    line-height: 1;

                    transition: .25s color, .25s background;

                    strong {
                        color: inherit;
                        text-transform: uppercase;
                        font-size: 14px;
                        display: block;
                        padding-bottom: 2px;
                    }

                    &.is-visited {
                        color: $gray;
                        background: $background-color;
                    }

                    &.is-done {
                        background: $green;
                        border-color: $green;
                        color: white;
                    }

                    &.is-active-day {
                        border: 2px solid #fff;
                        box-shadow: 0 0 0 2px $accent;

                        &:not(.is-done) {
                            background: white;
                            color: $text-color;
                        }
                    }
                }

                &__requests {
                    width: 50px;
                    text-align: center;
                    position: relative;
                    margin-top: (50px / 2);
                    padding-top: 10px;

                    &::after {
                        content: "";

                        top: 0;
                        left: 0;
                        position: absolute;

                        width: 50%;
                        border-bottom: 1px solid $border-color;
                    }

                    &::before {
                        content: "";

                        position: absolute;
                        top: 0;
                        bottom: 0;
                        border-right: 1px solid $border-color;
                        width: 0;

                        left: 50%;
                    }

                    button {
                        width: 30px;
                        height: 30px;
                        border: 2px solid $border-color;

                        i {
                            color: #fff;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        &.is-progress {
                            &-0 { background: $pink_dark; border-color: $pink_dark; }
                            &-25 { background: $orange; border-color: $orange }
                            &-50 { background: $blue-active; border-color: $blue-active  }
                            &-75 { background: $light_blue; border-color: $light_blue  }
                            &-100 { background: $green; border-color: $green }
                        }

                        &.is-active {
                            border: 2px solid #fff;
                            box-shadow: 0 0 0 2px $accent
                        }
                    }
                }
            }
        }
    }

    .menus {
        .days-switch {
            .day-block {
                position: relative;

                &::before {
                    content: "";

                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100%;

                    border-bottom:1px solid $border-color;
                }
            }

            &::before {
                display: none;
            }
        }

        .day-content {
            margin-left: 120px;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .days-switch {
            flex-grow: 0;
            flex-shrink: 0;

            width: 100px;
            margin-right: 20px;
        }

        &__right {
            flex: 1;
        }
    }

    .columns > .column.is-half > .section > .columns > .column.is-half {
        width: 100%;
    }

    .section {
        padding: 0;
        padding-bottom: 30px;

        &-title {
            margin-bottom: .75rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .h2 {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                i.material-icons {
                    margin-left: 5px;
                }
            }
        }
    }



    .details__right {
        .bp-panel:not(:last-child) {
            margin-bottom: 15px;
        }


        .days-header {
            border-top: 0 none;
            > div { flex-grow: 0; }
            > div:first-child {
                width: 150px;
                flex-grow: 1;
            }
        }
        .placeholder-view {
            flex-grow: 1;
            & > div:first-child { width: 150px; }
        }
    }
}
