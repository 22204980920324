/*                                                                    *\
========== The right hand side modal (for creating an invoice) =========
\*                                                                    */

.is-invoice-list {
    transition: padding 0.4s ease 0s;

    &.has-form-open { padding-right: 400px; }

    & > .table-wrapper {
        .cell, .title {
            flex: none;
            &:nth-child(1)  { flex: 1; }
            &:nth-child(2)  { width: 125px; }
            &:nth-child(3)  { flex: 1; }
            &:nth-child(4),
            &:nth-child(5)  { width: 70px; text-align: center; }
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9)  { width: 110px; text-align: right; }
            &:nth-child(10) { width: 50px; }
        }
    }

    .has-gray-bg { background-color: #EEE; }
}
.trip-dashboard .is-invoice-list.has-form-open { padding-right: 0; }
@media all and (max-width: 1700px) {
    .trip-dashboard .is-invoice-list.has-form-open { padding-right: 425px; }
}



/*                      *\
========== Aside =========
\*                      */
.modal-aside.is-invoice-form {
    top: 67px;
    pointer-events: none;

    .modal-overlay { display: none; }
    .modal-content {
        width: 451px;
        border-top: 2px solid $accent;
        border-left: 1px solid $border-color;
        padding: 30px 10px 80px 12px;
        pointer-events: auto;
        overflow-y: scroll;
    }

    h4 {
        padding-bottom: 20px;
        text-transform: uppercase;
    }
    table { width: 100%; }
    td { padding: 5px 0; }


    .row .cell { flex: none; }
    .row .cell:nth-child(1) { flex-grow: 1; }
    .row .cell:nth-child(2)      { width: 86px; text-align: right; }
    .row .cell:nth-last-child(2) { width:  86px; text-align: right; }
    .row .cell:last-child        { width:  15px; }
    .row .cell.is-actions { padding: 0 3px 0 0; }
    .is-empty { width: 100%; }

    .label {
        min-width: 165px;
        padding-top: 9px;
    }
    .textarea:not([rows]) { min-height: 70px; }

    .button.is-success { margin-bottom: 10px; }


    .total-for-list {
        width: 230px;
        text-align: right;
        & > div {
            height: 30px;
            position: relative;
            padding: 0 53px 0 15px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            align-items: center;
            display: flex;
        }
        label {
            flex-grow: 1;
            text-align: left;
        }
    }


    .is-empty {
        padding: 20px;
        text-align: center;
    }
}
