.is-catalog {
    > .main-panel {
        background :0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(#000, 0);

        > .tab-addition {
            margin-bottom: 30px;
        }
    }

    .panel.is-day {
        box-shadow: 0 0 0 rgba(#000, 0);

        > .panel-heading {
            font-size: 11px;
            padding-left: 15px;
            padding-right: 15px;
            border-left: 0;
            border-right: 0;
        }

        > .panel-block {
            padding-top: 0;
            padding-left: 15px;
            padding-right: 15px;
            border: 0;
        }

        .pell {
            border-top: 0;
            box-shadow: 0 0 0 rgba(#000, 0);
            border:0;
            margin-bottom: 15px;
    
            &-actionbar {
                background: 0;
            }
            &-content {
                border-bottom: 1px solid rgba(10, 10, 10, .1)
            }
        }
    }
}