.tooltip {
    z-index: 99999;
    display: block;

    .tooltip-inner {
        background: $gray-lighter;
        padding: 7px 10px;
        border-radius: $border-radius;
        color:$text-color;
        font-size: $font-size;
        text-align: center;
        border: 1px solid $gray;
        max-width: 800px;
    }

    .tooltip-arrow {
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        border-style: solid;
        position: absolute;
        background: $gray-lighter;
        display: none;

        margin:0 5px;
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            display: block;
            top: -7px;
            left: 49%;
            border-top: 1px solid $gray;
            border-left: 1px solid $gray;
        }
    }

    &[x-placement^="top"] {
        margin-bottom: 7px;

        .tooltip-arrow {
            display: block;
            bottom: -7px;
            left: 49%;
            border-bottom: 1px solid $gray;
            border-right: 1px solid $gray;
        }
    }

    .is-error &{
        .tooltip-inner {
            background: $red-light;
            border-color: $red-light;
            color: #fff;

            > * {
                color: inherit
            }
        }

        .tooltip-arrow {
            background-color: $red-light
        }

        &[x-placement^="bottom"] {
            .tooltip-arrow {
                border-top: 0;
                border-left: 0;
            }
        }

        &[x-placement^="top"] {
            .tooltip-arrow {
                border-bottom: 0;
                border-right: 0;
            }
        }
    }
}

.v-popover.is-large {
    .tooltip {
        &-inner {
            max-width: 450px;
        }
    }
}
