#app > .wrapper > div.is-user-dashboard { margin-bottom: 0; }
.is-user-dashboard {
    position: absolute;
    top: 10px;
    bottom: 10px;
    flex-direction: column;
    display: flex;

    h1 { margin-bottom: 20px; }
    & > div {
        flex-grow: 1;
        display: flex;
    }

    /*
        ================================== TODOS =============================
    */

    .todos {
        width: 380px;
        flex-grow: 0;
        flex-shrink: 0;

        @include box-with-top-color;
        background-color: $gray-lightest;
        margin-left: 20px;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        flex-direction: column;
        display: flex;

        & > div:nth-child(1) { // Title
            min-height: 40px;
            padding: 6px 10px;
            border-bottom: 1px solid $border-color;
            color: $text-color;
            font-size: $font-size;
            font-weight: 600;
            justify-content: space-between;
            align-items: center;
            display: flex;

            > div {
                display: flex;
                flex-direction: row;

                .v-popover {
                    margin-left: 10px;
                }
            }
        }

        & > div:nth-child(2) { // Select
            &:not(.is-hidden) {
                min-height: 40px;
                position: relative;
                padding: 6px 10px;
                border-bottom: 1px solid $gray-light;
            }


            > i {
                font-size: 16px;
                margin-right: 10px;
            }

            .input {
                margin-right: 10px;
            }
        }

        & > div:nth-child(3) { // Days filter
            min-height: 40px;
            background-color: $gray-lighter;
            position: relative;
            padding: 6px 10px;
            border-bottom: 1px solid $gray-light;
            font-weight: 600;
            .select {
                width: 80px;
                margin-left: 10px;
                vertical-align: 0;
            }
            span {
                width: 25px;
                height: 25px;
                background: white;
                position: absolute;
                top: 8px;
                right: 10px;
                border: 1px solid $input-border-color;
                border-radius: 50%;
                text-align: center;
                line-height: 24px;
            }
        }
    }


    .todo {
        margin-bottom: 0;
        border-top: 0 none;
        border-bottom: 1px solid $border-color;
        box-shadow: none;

        &:hover {
            background-color: $blue-lightest;

            .todo__info::after {
                background-image: linear-gradient(to right, transparent, $blue-lightest);
            }
        }
    }

    /*
       ================================== STATISTICS =============================
   */

    .statistics{
        width: 380px;
        flex-grow: 0;
        flex-shrink: 0;

        @include box-with-top-color;
        background-color: $gray-lightest;
        margin-left: 20px;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        flex-direction: column;
        display: flex;

        & > div:nth-child(1) { // Title
            min-height: 40px;
            padding: 6px 10px;
            color: $text-color;
            font-size: $font-size;
            font-weight: 600;
            justify-content: space-between;
            align-items: center;
            display: flex;

            > div {
                display: flex;
                flex-direction: row;
            }
        }
    }


    /*
        ================================== Orders =============================
    */

    .panel {
        display: flex;
        flex-direction: column;
    }

    .orders.redesign-table {
        @include box-with-top-color;
        background: 0;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        margin-bottom: 0;
        min-width: 0;

        .row {
            height: 34px;

            &.is-filters {
                min-height: 43px;
                padding: 7px 10px;
            }

            &.is-header {
                min-height: 41px;
            }

            &.is-head {
                height: 40px;
            }
        }

        .redesign-table__footer {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;
        }

        .b-l {
            border-left: 1px solid $gray-light;
            padding-left: 10px;
            height: 30px;
        }
    }
}
