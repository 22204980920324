/*
    Placeholder Modal
*/
.modal.placeholder {
    .material-icons, .fa { font-size: 16px; }
    .spacing-left { margin-left: 5px; }
    .no-spacing { margin-right: 0; }
    .has-margin-right { margin-right: 10px; }
    .has-margin-left  { margin-left:  10px; }

    label { cursor: pointer; }
    input[type="radio"] { vertical-align: -2px; }

    $circle-size: 36px;
    $inset-size: 32px;
    .score {
        width:  $circle-size;
        height: $circle-size;
        background-color: lighten($green, 25%);
        border-radius: 50%;
        display: inline-block;

        .slice, .fill {
            width:  $circle-size;
            height: $circle-size;
            position: absolute;
            -webkit-backface-visibility: hidden;
            transition: transform 0.2s ease-out;
            border-radius: 50%;
        }

        .slice {
            clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
            .fill {
                clip: rect(0px, $circle-size/2, $circle-size, 0px);
                background-color: $green;
            }
        }

        .text {
            width:       $inset-size;
            height:      $inset-size;
            background-color: white;
            position:    absolute;
            margin-left: ($circle-size - $inset-size)/2;
            margin-top:  ($circle-size - $inset-size)/2;
            line-height: $inset-size;
            text-align: center;
            border-radius: 50%;
        }

        $i: 1;
        $increment: 180deg / 100;
        @while $i <= 100 {
            &[data-score='#{$i}'] {
                .slice.full, .fill { transform: rotate($increment * $i); }
                .fill.bar          { transform: rotate($increment * $i * 2); }
                $i: $i + 1;
            }
        }
    }

    .no-name {
        width: 36px;
        height: 36px;
        background-color: $gray-lighter;
        padding-top: 3px;
        border-radius: 50%;
        border: 1px solid $gray-light;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
    }


    .stars {
        position: relative;
        top: -1px;
        padding-left: 10px;
        display: inline-block;
        i {
            margin-right: 0;
            color: $gray;
            font-size: 11px;
        }
        &.has-1 i:nth-child(1) { color:#000; }
        &.has-2 {
            i:nth-child(1), i:nth-child(2) { color:#000; }
        }
        &.has-3 {
            i:nth-child(1), i:nth-child(2), i:nth-child(3) { color:#000; }
        }
        &.has-4 {
            i:nth-child(1), i:nth-child(2), i:nth-child(3), i:nth-child(4) { color:#000; }
        }
        &.has-5 {
            i:nth-child(1), i:nth-child(2), i:nth-child(3), i:nth-child(4), i:nth-child(5) { color:#000; }
        }
    }

    .tag {
        height: 18px;
        margin-left: 12px;
        text-transform: none;
    }


    .is-date { width: 73px; }
    .has-time .flatpickr-input { width: 46px; }


    .has-input-radio {
        margin-right: 33px;
        cursor: pointer;
        input[type="radio"] { margin-left: 0; }
    }


    .modal-content > header .header-extra-info > div {
        align-items: center;
        display: flex;
    }


    .header-extra-info {
        padding: 10px 0 10px;
        font-weight: 500;
        display: flex;

        & > div {
            padding: 0 10px;
            border-right: 1px solid $gray-lighter;
            line-height: 25px;
            &:first-child { padding-left: 0; }
            &:last-child  { border-right: 0 none; }

            a {
                color: $link-color
            }
        }
    }


    .deadline-date {
        margin-top: -2px;
        padding: 2px 0;
        &.is-red, &.is-yellow {
            height: auto;
            padding: 2px 5px 1px;
        }
    }


    .modal-content {
        border-top: 2px solid $accent;

        & > header {
            background-color: white;
            padding: 10px 20px 5px;
            text-align: left;
            display: block;

            .name {
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .is-add-new {
                z-index: 3;
                position: absolute;
                top: 38px;
                right: 37px;
            }

            .is-saving {
                position: absolute;
                top: 6px;
                right: 30px;
                i {
                    color: $accent;
                    font-size: 19px;
                }
            }
        }

        &

        footer { display: none; }
    }


    .modal-body {
        background-color: $gray-lighter;
        padding: 25px 20px 10px;
        border-radius: 0 0 5px 5px;
    }


    .buttons-right {
        text-align: right;
        button { min-width: 145px; }
    }

    .buttons.has-addons {
        margin-bottom: 0;
        i { margin-right: 0; }
    }


    .bp-panel.is-form-contingent {
        height: 100%;
        margin-bottom: 0;

        .material-icons { margin-right: 0; }

        header, .row {
            & > div:nth-child(4) { width: 90px; }
        }

        .extra-people > div:first-child {
            align-items: center;
            display: flex;
            .material-icons { margin-right: 5px; }
        }

        .has-margin-top { margin-top: -3px; }
    }
}



@import './requests';
@import './prices';
@import './costs';
@import './emails';

@import './new_request';
