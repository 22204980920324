
.vue-slider {
    width: 230px !important;
    padding: 0 !important;
    height: 15px !important;

    &-dot {
        width: 21px !important;
        height: 21px !important;
    }

    &-process {
        border-radius: $border-radius;
    }

    &-rail {
        border-radius: $border-radius;
    }

    &.is-0 .vue-slider-process { background: $pink_dark; }
    &.is-25 .vue-slider-process { background: $orange; }
    &.is-50 .vue-slider-process { background: $blue-active; }
    &.is-75 .vue-slider-process { background: $light_blue; }
    &.is-100 .vue-slider-process { background: $green; }
}