.days-header {
    min-height: 30px;
    background-color: $gray-light;
    position: relative;
    padding: 0 85px 0 0;
    border-top: 2px solid $accent;
    border-radius: $border-radius;
    align-items: center;
    color: $text-color;
    font-weight: 600;
    display: flex;
    .fa { font-size: 13px; }

    & > div {
        width: 150px;
        padding: 0 12px;
        flex: none;
        align-items: center;
        display: flex;
    }
    & > div:nth-child(1) {
        width: 330px;
        flex: 1;
        i { margin-left: 2px; }
    }

    .is-60  { width:  60px; }
    .is-100 { width: 100px; }
    .is-120 { width: 120px; }
    .is-130 { width: 130px; }
    .is-160 { width: 160px; }
    .is-190 { width: 190px; }
    .is-250 { width: 250px; }

    .buttons {
        position: absolute;
        top: 5px;
        right: -3px;
        justify-content: flex-end;
        button { margin-left: 10px; }
    }
}


.day {
    background-color: $background-color;
    position: relative;
    margin-bottom: 45px;
    border-bottom: 1px solid $border-color;
    box-shadow: none;
    font-size: $font-size;



    &.is-empty {
        padding: 0;
        & > header {
            margin-bottom: 0;
            border-bottom: 0 none;
        }
    }

    & > .accordion-header {
        min-height: 30px;
        padding: 0;

        &.is-static > header { padding-right: 10px; }

        & > header {
            min-height: 30px;
            background-color: $gray-lighter;
            position: relative;
            margin-top: -2px;
            padding-left: 10px;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            font-weight: 600;
            color: $text-color;
            text-transform: uppercase;
            align-items: center;
            display: flex;
            &.bp-row-form { z-index: 2; }

            .column {
                padding-top: 2px;
                padding-bottom: 3px;
            }
            .loading-data {
                width: auto;
                .spinner { margin: 0 20px; }
            }
        }

        .day-action-buttons {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 27px;
            align-items: center;
            display: flex;
        }
    }
    &.has-hidden-arrow > .accordion-header{
        &:after { content: none; }
        .day-action-buttons { margin-right: 0; }
    }

    &.is-not-collapsible .day-action-buttons {
        margin-right: 10px;
    }

    .simple-menu {
        width: 30px;
        height: 100%;
        text-align: left;
        & > button {
            width: 100%;
            height: 100%;
            transition: transform 0.2s ease-out;
            & > i { margin-right: 0; }
            i { color: $blue-dark; }
            &:hover i { transform: scale(1.2); }
        }
        i { color: white; }
    }


    label { @include user-select(none); }
    input[type="checkbox"], input[type="radio"] {
        margin: 1px 7px 0 10px;
        vertical-align: -2px;
    }
    input[type="radio"] { vertical-align: 0; }

    button.btn-delete:hover   i { color: $red; }
    button.btn-edit:hover     i { color: $blue; }

    .material-icons, .fa {
        margin-right: 5px;
        font-size: 18px;
    }
    .fa { vertical-align: -2px; }


    // Contingent switching orders
    .order-second-last .contingent-component > div > div:nth-child(2) { order: 3; }


    .modal.day-concept .modal-body {
        height: auto;
        overflow-y: visible;
    }
}


.bp-row-form {
    background-color: $gray-lighter;
    position: relative;
    padding: 5px 10px 5px;
    z-index: 2;
    display: flex;

    &.extra-info {
        background: white;
        font-weight: 500;

        & > div {
            padding: 0 10px;
            border-right: 1px solid $gray-lighter;
            line-height: 25px;
            &:first-child { padding-left: 0; }
            &:last-child  { border-right: 0 none; }

            a {
                color: $link-color
            }
        }
    }

    .select { height: 30px; }

    .bp-row-form__title {
        padding-top: 5px;
        margin-bottom: 5px;
        color: $text-color;
        word-break: normal;
        &:before {
            content: 'keyboard_arrow_right';
            @include font-family-material;
            width: 30px;
            position: relative;
            font-size: 18px;
            font-weight: $font-weight-normal;
        }
    }


    & > div { // column
        padding-left: 20px;
        &:first-child { padding-left: 0; }


        &.is-long { width: 500px; }
        &.is-160, .is-160 { width: 160px; }
        &.is-flex-grow { max-width: 500px; }


        & > div { // row
            padding-bottom: 5px;
            align-items: center;
            display: flex;
        }
        .material-icons, .fa {
            min-width: 29px;
            margin-right: 0;
        }
        .btn-single-toggle:first-child {
            margin-right: 8px;
            flex-shrink: 0;
        }
    }


    & > .is-close, & > .is-save {
        position: absolute;
        top: 100%;
        right: 0;
        i { margin-right: 0; }
    }
    & > .is-close { right: 37px; }


    .has-margin-right { margin-right: 10px; }
    .has-margin-left { margin-left: 10px; }
    .has-time .flatpickr-input { width: 44px; }
    .is-static-date {
        margin-right: 10px;
        padding-left: 5px;
    }

    .buttons.toggle-switch { margin-bottom: 0; }
    .btn-single-toggle i {
        min-width: 0;
        font-size: 14px;
    }

    .is-100 { width: 100px; }
}


.bp-row-form.is-day-subtitle-form {
    background-color: $gray-lighter;
    position: relative;
    z-index: 2;
}


@import './placeholders';
@import './add_new_day';
