.modal.placeholder {
    .accordion {
        margin-bottom: 25px;

        .accordion-header {
            & > div {
                display: flex;

                & > div:first-child {
                    padding-right: 20px;
                    flex: none;
                    flex-basis: 36px;
                    align-items: center;
                    display: flex;
                }
                & > div:nth-child(2) {
                    padding: 9px 0 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    flex: 1;
                    overflow: hidden;
                }
                & > div:nth-child(3) {
                    padding: 9px 20px 10px 0;
                    justify-content: flex-end;
                    display: flex;
                    input[type="checkbox"] { vertical-align: -4px; }
                }
            }
            .header-extra-info {
                padding-top: 5px;
                text-transform: none;

                > div {
                    white-space: nowrap
                }
            }


        }

        .accordion-content {
            & > div {
                padding: 20px 25px;

                & > .buttons {
                    margin-bottom: 40px;
                    button { min-width: 200px; }
                }
            }
        }
    }

    .explanation {
        justify-content: flex-end;
        display: flex;
        i { margin: 0 2px 0 25px; }
    }


    .has-big-select {
        width: 570px;
        align-items: center;
        display: flex;
        .multiselect {
            margin-left: 5px;
            flex-grow: 1;
            &:nth-child(3), &:nth-child(4) {
                width: 140px;
                flex: none;
            }
        }
    }

    .has-3-multiselects {
        width: 680px;
        & > div {
            width: 32%;
            flex: none;
        }
    }
}


.bp-box {
    border-top: 2px solid $accent;
    margin-bottom: 25px;
    padding: 0;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    header {
        min-height: 35px;
        background-color: $background-color;
        margin-bottom: 0;
        padding: 3px 10px;
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: 1px solid $border-color;
        justify-content: flex-start;
        align-items: center;
        display: flex;
    }
    main {
        background-color: $background-color;
        padding: 5px 10px;
        line-height: 26px;
        border-radius: 0 0 $border-radius $border-radius;
    }

    &.is-info main {
        flex-wrap: wrap;
        align-items: center;
        display: flex;

        & > div {
            padding-right: 25px;
            align-items: center;
            display: flex;
        }
        & > div:last-child { padding-right: 0; }

        .select, input, .multiselect { margin-left: 5px; }
        .flatpickr, .multiselect { display: inline-block; }
        .input {
            width: auto;
            &.is-larger {
                width: 300px;
            }
        }
        .is-date { width: 73px; }

        .is-one-row {
            width: 100%;
            margin-bottom: 10px;
            flex: none;
            display: flex;
            & > div {
                padding-right: 25px;
                align-items: center;
                display: flex;
            }
        }

        .is-last-row {
            width: 100%;
            flex: none;
            display: flex;
            & > div {
                padding-right: 25px;
                align-items: center;
                display: flex;
            }
        }
        .is-300 { width: 300px; }
        label { min-width: 95px; }
    }

    .select, select, .multiselect { width: auto; }
    .material-icons, .fa { vertical-align: -3px; }


    table {
        width: 100%;
        margin: -5px -10px;
        td:first-child label input { margin-left: 0; }
    }
    tr {
        border-bottom: 1px solid $border-color;
        &:last-child { border-bottom: 0 none; }
    }
    td {
        height: 37px;
        vertical-align: middle;
        &:nth-child(1) { padding-left: 10px; }
    }


    .btn-delete:hover i { color: $red; }

    &.is-flex {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-bottom: 0;

        .flex-grow {
            flex: 1;
        }
    }
}
