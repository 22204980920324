.modal.placeholder {
    .is-provider {
        header {
            & > div:nth-child(1) {
                margin: -5px 0 -7px -5px;
                transform: scale(0.8);
            }
            & > div:nth-child(2) {
                padding-left: 9px;
                text-transform: uppercase;
                flex-grow: 1;
                .stars { vertical-align: 2px; }
            }
        }

        main {
            display: flex;
            & > div { padding-right: 25px; }

            a { color: $blue-light; }
            a:hover { text-decoration: underline; }
        }
    }

    .new-request {
        background-color: $gray-lighter;
        position: relative;
        //padding: 10px 20px;
        border-radius: 0 0 $border-radius $border-radius;
        text-align: right;

        .accordion { text-align: left; }
        & > button {
            min-width: 145px;
            margin-bottom: 15px;
        }

        .accordion-header .fa-plus-circle {
            margin-right: 4px;
            color: $green;
            font-size: 37px;
        }
    }

    .overall-buttons { margin-bottom: 10px; }
}
