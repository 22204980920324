.accordion {
    background-color: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    &:not(.no-border) {
        border-top: 2px solid $accent;
    }
}
.client-visit {
    &:not(.no-border) {
        border-top: 2px solid #60A4AB;
    }
}
.client-accordion {
    background-color: #f7f9fa;
    border-top: 1px solid #dddddd !important;
    .content p{
        font-size: 15px;
        margin-left: 12px;
    }
    h4 {
        margin-top: 13px;
        margin-right: 23px;
    }
    div:nth-child(2) {
        justify-content: flex-end;
        .user-image {
            margin-right: 25px;
            margin-top: 4px;
        }
    }
}

.accordion-header {
    min-height: 40px;
    position: relative;
    padding: 0 10px;

    &:not(.is-static) {
        &:after {
            content: 'keyboard_arrow_down';
            width: 30px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            @include font-family-material;
            transition: transform 0.2s ease-out;
            display: flex;
        }
    }

    &.is-margin {
        margin-bottom: 5px;
    }

    &.is-active {
        box-shadow: 0 2px 0 $accent inset;
    }
}
.is-open > .accordion-header:after { transform: rotate(180deg); }
.is-not-collapsible > .accordion-header:after { content: none; }


.accordion-content {
    position: relative;
    box-shadow: 0 -1px 0 $border-color;
    transition: height 0.2s ease-out;
}


.accordion.is-list-with-add {
    .header-text {
        font-weight: 700;
    }
    .accordion-header {
        padding-right: 35px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        button {
            i { color: $button-color; }
            display: none;
        }
    }

    .accordion-content > div {
        padding: 10px 15px 10px;
    }
}
.accordion.is-list-with-add.is-open .accordion-header button { display: block; }

.accordion.header-is-highlighted  {
    .accordion-header {
        background: $green-bright2;
    }
}
